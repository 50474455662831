import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {postJson} from "../../common";
import Table from "../common/Table";
import Modal from '../common/Modal';

function fetchOrders() {
    return postJson('/api/orders').then((response) => {
        if (response.orders) {
            return response.orders;
        }
        return [];
    });
}

const orderTableColumns = [
    {label: 'Items', Component: ({order}) => <div>{order.lineItems.map((li) => li.title).join(', ')}</div>},
    {label: 'Total', Component: ({order}) => <div>{order.total} {order.currency}</div>},
    {label: 'Customer', Component: ({order}) => <div>
            {order.displayName !== 'Someone' ? order.displayName : 'n/a'}
    </div>},
];

function replay(item) {
    return postJson('/api/orders/replay', {orderId: item._id});
}

function Orders() {
    const [orders, setOrders] = useState([]);
    const [dialog, setDialog] = useState(null);
    const openDialog = (item) => {
        setDialog(item);
    }
    const closeDialog = () => {
        setDialog(null);
    }

    useEffect(() => {
        fetchOrders().then((newOrders) => {
            setOrders(newOrders);
        });
    }, []);

    return <div>
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">Orders</h1>
            </div>
        </header>
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <div className="border-4 border-gray-200 rounded-lg p-4">
                        <h2 className="text-2xl mb-2">Recent orders</h2>
                        <div className="flex flex-col">
                            <Table
                                columns={orderTableColumns}
                                items={orders}
                                actions={[
                                    {action: openDialog, text: 'DETAILS'},
                                    {action: replay, text: 'REPLAY'},
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Modal open={Boolean(dialog)} handleClose={closeDialog}>
            {dialog && dialog.lineItems.map((item, i) => <div key={i}>
                {item.title}
            </div>)}
        </Modal>
    </div>
}

Orders.propTypes =
    {}
;

const mapStateToProps = (state) => (
    {}
);

export default connect(mapStateToProps,
    {
        Orders,
    },
)(withRouter(Orders));
