import React from "react";
import {withRouter} from "react-router-dom";
import {useUserContext} from "../../App";
import ConnectionPanel from "../common/ConnectionPanel";
import ShopifyConnectionPanel from "../shopify/ShopifyConnectionPanel";
import {ClipboardCopyIcon} from '@heroicons/react/solid';

function Settings() {
    const {user, obsOverlayActive} = useUserContext();

    const obsLink = `https://shop-connector.herokuapp.com/overlay/${user.twitchUsername}`;

    return <div>
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
            </div>
        </header>
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="flex-col space-y-4 px-4 sm:px-0">
                    <div className="border-4 border-gray-200 rounded-lg p-4 flex-col space-y-2">
                        <h2 className="text-2xl mb-2">Setup overlay in OBS</h2>
                        <div>Add Browser Source with the following URL:</div>
                        <div className="mt-1 flex rounded-md shadow-sm w-1/2">
                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                <input
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300"
                                    disabled
                                    value={obsLink}
                                />
                            </div>
                            <button
                                onClick={() => navigator.clipboard.writeText(obsLink)}
                                type="button"
                                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <ClipboardCopyIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Copy to clipboard</span>
                            </button>
                        </div>
                        {obsOverlayActive && <div>OBS Browser Source detected 👍</div>}
                    </div>
                    <div className="border-4 border-gray-200 rounded-lg p-4">
                        <h2 className="text-2xl mb-2">Connections</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <ConnectionPanel
                                label={'Twitch'}
                                connected={true}
                                className={'border-twitchPurple'}
                                identifier={user.twitchUsername}
                            />
                            <ShopifyConnectionPanel/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
}

export default withRouter(Settings);
