import React, {createContext, useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Provider} from 'react-redux';
import store from './store';
import {fetchJson} from './common';

import Footer from './components/layout/Footer';
import Landing from './components/layout/Landing';

import './App.css';
import Profile from './components/pages/Profile';
import Orders from './components/pages/Orders';
import Settings from './components/pages/Settings';
import Help from './components/pages/Help';
import Navbar from './components/layout/Navbar';
import Overlay from './components/pages/Overlay';
import socket from './socket';

function fetchUser() {
    return fetchJson('/api/backend/user').then((response) => {
        return response.user;
    });
}

function fetchWebhooks() {
    return fetchJson('/api/shopify/webhooks').then((response) => {
        return response.webhooks || [];
    });
}

const UserContext = createContext();

export function useUserContext() {
    return useContext(UserContext);
}

function omit(obj, prop) {
    const {[prop]: removed, ...remaining} = obj;
    return Object.assign({}, remaining);
}

function App() {
    const [user, setUser] = useState(null);
    const [webhooks, setWebhooks] = useState([]);

    const [connectedSockets, setConnectedSockets] = useState({});
    const addSocket = (id, obs) => {
        setConnectedSockets((previous) => Object.assign({}, previous, {[id]: obs}));
    }
    const removeSocket = (id) => {
        setConnectedSockets((previous) => omit(previous, id));
    }

    const obsOverlayActive = Object.values(connectedSockets).some((obs) => obs);

    useEffect(() => {
        fetchUser().then((user) => {
            setUser(user);
            if (user) {
                fetchWebhooks().then(setWebhooks);
            }
        });
    }, []);

    useEffect(() => {
        if (user && !window.location.pathname.includes('/overlay')) {
            socket.emit('init-app', user.twitchUsername); // TODO: use random hash later
            socket.on('overlay-socket-connected', ({socketId, obsUserAgent}) => {
                addSocket(socketId, obsUserAgent);
            });
            socket.on('overlay-socket-disconnected', (id) => {
                removeSocket(id);
            });
        }
    }, [user]);

    console.log('webhooks', webhooks);

    return (
        <Provider store={store}>
            <UserContext.Provider value={{user, webhooks, obsOverlayActive}}>
                <Router>
                    <Switch>
                        <Route
                            path="/overlay/:channel"
                            component={Overlay}
                        />
                        <Route path="" render={() => user ? <>
                            <Navbar user={user}/>
                            <div className="App">
                                <Route exact path="/" component={Profile}/>
                                <Route exact path="/dashboard" component={Profile}/>
                                <Route exact path="/orders" component={Orders}/>
                                <Route exact path="/settings" component={Settings}/>
                                <Route exact path="/help" component={Help}/>
                                <Footer/>
                            </div>
                        </> : <Landing/>}/>
                    </Switch>
                </Router>
            </UserContext.Provider>
        </Provider>
    );
}

export default App;
