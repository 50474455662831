import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
//import Chart from "chart.js"
import {useUserContext} from "../../App";

function Profile() {
    const {user} = useUserContext();

    if (!user) {
        return 'Loading...';
    }

    return <div>
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
                <h3 className="text-xl font-bold text-gray-900">Welcome {user.twitchUsername}</h3>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.bundle.min.js" integrity="sha256-xKeoJ50pzbUGkpQxDYHD7o7hxe0LaOGeguUidbq6vis=" crossorigin="anonymous"></script>


            </div>
        </header>
        <main>
            <div className="main content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">

                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
                        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
                            <div className="flex flex-row items-center">
                                <div className="flex-shrink pr-4">
                                    <div className="rounded-full p-5 bg-green-600"><i className="fa fa-wallet fa-2x fa-inverse"></i></div>
                                </div>
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold uppercase text-gray-600">Total Revenue</h5>
                                    <h3 className="font-bold text-3xl">$ sehr viel <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
                        <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
                            <div className="flex flex-row items-center">
                                <div className="flex-shrink pr-4">
                                    <div className="rounded-full p-5 bg-pink-600"><i className="fas fa-users fa-2x fa-inverse"></i></div>
                                </div>
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold uppercase text-gray-600">Total Users Today</h5>
                                    <h3 className="font-bold text-3xl">42 <span className="text-pink-500"><i className="fas fa-exchange-alt"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
                        <div className="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5">
                            <div className="flex flex-row items-center">
                                <div className="flex-shrink pr-4">
                                    <div className="rounded-full p-5 bg-yellow-600"><i className="fas fa-user-plus fa-2x fa-inverse"></i></div>
                                </div>
                                <div className="flex-1 text-right md:text-center">
                                    <h5 className="font-bold uppercase text-gray-600">New Users</h5>
                                    <h3 className="font-bold text-3xl">alle <span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
                                </div>
                            </div>
                        </div>
                    </div>                                                            
                </div>

            <div className="flex flex-row flex-wrap flex-grow mt-2">
                
            <div className="w-full md:w-1/2 xl:w-1/3 p-6">
                <div className="bg-white border-transparent rounded-lg shadow-xl">
                    <div className="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
                        <h5 className="font-bold uppercase text-gray-600">Units sold</h5>
                    </div>
                    <div className="App">
                    <Bar data={sold}/>
                    </div>
                </div>
            </div>
        

        
            <div className="w-full md:w-1/2 xl:w-1/3 p-6">
                <div className="bg-white border-transparent rounded-lg shadow-xl">
                    <div className="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
                        <h5 className="font-bold uppercase text-gray-600">User</h5>
                    </div>
                    <div className="App">
                    <Line data={users}/>
                    </div>
                </div>
            </div>
        

            </div>            
        </div>     
    </main>
</div>
}

const sold = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Seb", "Oct", "Nov", "Dec"],
  datasets: [
    {
        label: 'Huddy',
        data: [33, 53, 85, 41, 44, 65, 42, 12, 90, 30, 0, 0],
        fill: true,
        backgroundColor: "rgba(201, 76, 76)"
    },
    {
        label: 'Shirt',
        data: [ 65, 42, 12, 90, 30, 33, 53, 85, 41, 44, 0 , 0],
        fill: true,
        backgroundColor: "rgba(0, 255, 0)"
    }
  ]
};

const users= {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Seb", "Oct", "Nov", "Dec"],
    datasets: [
      {
          label: 'New',
          data: [2, 10, 4, 6, 2, 8, 0, 5, 7, 12, 0, 0],
          fill: true,
          backgroundColor: "rgba(76, 76, 76,0.2)",
          borderColor : "rgba(76, 76, 76,1)",
      },
      { 
          label: 'Total',
          data: [ 60, 90, 99, 24, 83, 49, 30, 69, 66, 10, 0 , 0],
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
      }
    ]
  };

Profile.propTypes =
    {}
;

const mapStateToProps = (state) => (
    {}
);

export default connect(mapStateToProps,
    {
        Profile,
    },
)(withRouter(Profile));
