import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function Help() {
    return <div>
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">Help</h1>
            </div>
        </header>
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <div className="border-4 border-dashed border-gray-200 rounded-lg h-96"/>
                </div>
                {/* /End replace */}
            </div>
        </main>
    </div>

}

Help.propTypes =
    {}
;

const mapStateToProps = (state) => (
    {}
);

export default connect(mapStateToProps,
    {
        Help,
    },
)(withRouter(Help));
