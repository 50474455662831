import React, {useMemo, useState} from 'react';
import {fetchJson, postJson} from "../../common";
import Input from "../common/Input";
import Button, {InlineButton} from "../common/Button";
import ConnectionPanel from "../common/ConnectionPanel";
import {useUserContext} from "../../App";

// const SHOPIFY_AUTH_URL = '/api/shopify/init-redirect';

// function initShopifyAuth(shop) {
//     fetchJson(`${SHOPIFY_AUTH_URL}?shop=${shop}`).then((response) => {
//         window.location = response.url;
//     });
// }

console.log('ngrok url', process.env);

function ShopifyConnectionPanel() {
    const {user, webhooks} = useUserContext();

    const warning = useMemo(() => {
        if (webhooks.length === 0) {
            return 'No webhooks configured';
        }
        return null;
    }, [webhooks]);

    const [shopifyInput, setShopifyInput] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [password, setPassword] = useState('');

    function storeApiCredentials() {
        postJson('/api/shopify/storePrivateAppCredentials', {shop: shopifyInput, apiKey, password})
            .then((response) => {
                console.log(response);
            });
    }

    function recreateWebhooks() {
        fetchJson('/api/shopify/recreate-webhooks');
    }

    return <ConnectionPanel
        label={'Shopify'}
        connected={user.shopify}
        warning={warning}
        className={'border-shopifyGreen'}
        identifier={user.shopify && user.shopify.shopUrl}
        RefreshConnection={<InlineButton onClick={recreateWebhooks}>
            Recreate webhooks
        </InlineButton>}
        InitConnection={<div className="flex space-y-2 flex-col w-full md:w-1/2">
            <Input
                placeholder={'Enter shop name'}
                value={shopifyInput}
                onChange={(e) => {
                    setShopifyInput(e.target.value);
                }}
            />
            <Input
                placeholder={'Enter API key'}
                value={apiKey}
                onChange={(e) => {
                    setApiKey(e.target.value);
                }}
            />
            <Input
                placeholder={'Enter password'}
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            <Button
                onClick={storeApiCredentials}
                disabled={shopifyInput.length === 0 || apiKey.length === 0 || password.length === 0}
            >
                Click to connect
            </Button>
        </div>}
        additionalContent={webhooks.length > 0 && <>
            <div className="w-full border-t border-gray-300 mt-2 mb-2"/>
            <div className="flex space-y-2 flex-col w-full">
                {webhooks.map((wh, i) => <div key={i}>
                    <div>Webhook {i + 1}</div>
                    <div>Address: <code>{wh.host}</code></div>
                    <div>Created at: <code>{wh.created_at}</code></div>
                    <div>API Version: <code>{wh.api_version}</code></div>
                </div>)}
            </div>
        </>}
    />
}

export default ShopifyConnectionPanel;
