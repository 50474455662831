export function fetchJson(url) {
    return fetch(url, {credentials: 'include'}).then((res) => res.json());
}

export function postJson(url, data) {
    return fetch(new Request(url, {
        credentials: 'include',
        cors: 'no-cors',
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })).then((res) => res.json());
}
