import React from "react";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";

/* This example requires Tailwind CSS v2.0+ */
import {Fragment} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {BellIcon, MenuIcon, XIcon} from '@heroicons/react/outline'

const navigation = [
    {name: 'Dashboard', href: '/dashboard'},
    {name: 'Orders', href: '/orders'},
    {name: 'Settings', href: '/settings'},
    {name: 'Help', href: '/help'},
]
const profile = [
    {name: 'Sign out'},
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function NavbarButton({item}) {
    const location = useLocation();
    const active = item.href === location.pathname;
    const className = active ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white";
    return <Link
        to={item.href}
        className={`px-3 py-2 rounded-md text-sm font-medium ${className}`}
    >
        {item.name}
    </Link>
}

function Navbar({user, logoutUser}) {
    return <Disclosure as="nav" className="bg-gray-800">
        {({open}) => <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <img
                                className="h-8 w-8"
                                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                                alt="Workflow"
                            />
                        </div>
                        <div className="hidden md:block flex-shrink-0">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {navigation.map((item, i) => <NavbarButton item={item} key={i}/>)}
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            {/*<button
                                className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true"/>
                            </button> */}

                            {/* Profile dropdown */}
                            <Menu as="div" className="ml-3 relative">
                                <div>
                                    <Menu.Button
                                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={user.profileImageUrl}
                                            alt=""
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {profile.map((item, i) => <Menu.Item key={i}>
                                            {({active}) => <a
                                                onClick={logoutUser}
                                                href="/logout"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-gray-700'
                                                )}
                                            >{item.name}</a>}
                                        </Menu.Item>)}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button
                            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open main menu</span>
                            {open
                                ? <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                : <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                            }
                        </Disclosure.Button>
                    </div>
                </div>
            </div>
            <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {navigation.map((item, i) =>
                        item.href === window.location.pathname || window.location.pathname === '/' ? (
                            <Fragment key={i}>
                                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                <Link to={item.href}
                                      className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
                                    {item.name}
                                </Link>
                            </Fragment>
                        ) : (
                            <Link
                                key={i}
                                to={item.href}
                                className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                            >
                                {item.name}
                            </Link>
                        )
                    )}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                            <img
                                className="h-10 w-10 rounded-full"
                                src={user.profileImageUrl}
                                alt=""
                            />
                        </div>
                        <div className="ml-3">
                            <div
                                className="text-base font-medium leading-none text-white">{user.twitchUsername}</div>
                            <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                        </div>
                        <button
                            className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                        {profile.map((item) => (
                            <a
                                key={item}
                                href="/logout"
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                            >
                                {item}
                            </a>
                        ))}
                    </div>
                </div>
            </Disclosure.Panel>
        </>}
    </Disclosure>
}

Navbar.propType = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapsStateToProps = (state) => (
    {
        auth: state.auth,
    }
);

export default connect(mapsStateToProps,
    {
        logoutUser
    }
)(Navbar);
