import React from "react";

const MyComponent = () => {
  return (
    <footer className="fixed bottom-0 w-full h-100 bg-dark text-black mt-5 p-4 text-center">
      Copyright &copy; {new Date().getFullYear()} Twify
    </footer>
  );
};

export default MyComponent;
