import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import socket from "../../socket";
// import OverlayBackground from '../../public/overlay-bg.png';

// const mockOrder = {
//     "_id": "614b3d485fbf7a13a456e606",
//     "receivedAt": "2021-09-22T14:27:20.324Z",
//     "shopName": "twify-dev.myshopify.com",
//     displayName: 'nice__username',
//     "total": 10,
//     "currency": "EUR",
//     "lineItems": [{
//         "_id": "614b3d485fbf7a13a456e607",
//         "shopInternalId": "6746600571060",
//         "title": "OneTaKe Hoodie",
//         "quantity": 1,
//         "price": 10,
//         "currency": "EUR",
//         "images": [{
//             "_id": "614b3d485fbf7a13a456e608",
//             "width": 480,
//             "height": 627,
//             "src": "https://cdn.shopify.com/s/files/1/0579/1032/9524/products/oneTAKE_HoodieBlack_006_480x_5713cf2f-0fb2-4e93-b0b9-d4f6665eeb58.jpg?v=1625684373"
//         }]
//     }, {
//         "_id": "614b3d485fbf7a13a456e607",
//         "shopInternalId": "6746600571060",
//         "title": "OneTaKe Hoodie",
//         "quantity": 1,
//         "price": 10,
//         "currency": "EUR",
//         "images": [{
//             "_id": "614b3d485fbf7a13a456e608",
//             "width": 480,
//             "height": 627,
//             "src": "https://cdn.shopify.com/s/files/1/0579/1032/9524/products/oneTAKE_HoodieBlack_006_480x_5713cf2f-0fb2-4e93-b0b9-d4f6665eeb58.jpg?v=1625684373"
//         }]
//     }],
//     "buyer": "614b3d475fbf7a13a456e605",
//     "__v": 0
// }

function Overlay() {
    const {channel} = useParams();
    const [order, setOrder] = useState(null);
    const [queue, setQueue] = useState([]);

    useEffect(() => {
        if (channel) {
            socket.emit('init', channel);
            socket.on('order', (data) => {
                setQueue((oldQueue) => [...oldQueue, data]);
            });
            socket.on('report-user-agent', () => {
                socket.emit('handle-user-agent', channel, navigator.userAgent);
            });
        }
    }, [channel]);

    useEffect(() => {
        if (order === null && queue.length > 0) {
            const orderToDisplay = queue.shift();
            setQueue([...queue]);
            setOrder(orderToDisplay);
            setTimeout(() => {
                setOrder(null);
            }, 6000);
        }
    }, [queue, order]);

    if (!order) {
        return null;
    }
    return <Order order={order}/>
}

function Order({order: {lineItems, total, currency, displayName}}) {
    lineItems = lineItems.filter((_, i) => i === 0);
    return <div className={'order'} style={{backgroundImage: `url('/overlay-bg.png')`}}>
        <div className={'order-summary'}>
            <div>{displayName || 'Someone'} just
                bought {lineItems.length > 1 ? lineItems.length : 'an'} item{lineItems.length > 1 && 's'}!
            </div>
            <div>Total: {total} {currency}</div>
            {/*<div style={{marginTop: 6}}>GET YOURS AT WWW.ONETAKE.GG</div>*/}
        </div>
        <div className={'order-items'}>
            {lineItems.map((li, i) => <div key={i} className={'line-item'}>
                {li.images && li.images.length > 0 && <img src={li.images[0].src} alt={li.title}/>}
                {/*<div>{li.title}</div>*/}
            </div>)}
        </div>
    </div>
}

export default Overlay;