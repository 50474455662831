import React from 'react';
import {SlimButton} from './Button';

function Table({columns, items, actions}) {
    return <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            {columns.map((column, i) => <th
                                key={i}
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {column.label}
                            </th>)}
                            {actions.map((_, i) => <th key={i} scope="col"/>)}
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {items.map((item, i) => <tr key={i}>
                            {columns.map((column, j) => <td key={j} className="px-6 py-4 whitespace-nowrap">
                                <column.Component order={item}/>
                            </td>)}
                            {actions.map(({action, text}, j) => <td key={j} className="px-6 py-4">
                                <SlimButton onClick={() => action(item)}>{text}</SlimButton>
                            </td>)}
                            {/*<td className="px-6 py-4 whitespace-nowrap">*/}
                            {/*    <div className="flex items-center">*/}
                            {/*        <div className="flex-shrink-0 h-10 w-10">*/}
                            {/*            <img className="h-10 w-10 rounded-full" src={item.image} alt=""/>*/}
                            {/*        </div>*/}
                            {/*        <div className="ml-4">*/}
                            {/*            <div className="text-sm font-medium text-gray-900">{item.name}</div>*/}
                            {/*            <div className="text-sm text-gray-500">{item.email}</div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</td>*/}
                            {/*<td className="px-6 py-4 whitespace-nowrap">*/}
                            {/*    <div className="text-sm text-gray-900">{item.title}</div>*/}
                            {/*    <div className="text-sm text-gray-500">{item.department}</div>*/}
                            {/*</td>*/}
                            {/*<td className="px-6 py-4 whitespace-nowrap">*/}
                            {/*    <span*/}
                            {/*      className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">*/}
                            {/*    Active*/}
                            {/*  </span>*/}
                            {/*</td>*/}
                            {/*<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.role}</td>*/}
                            {/*<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">*/}
                            {/*    <a href="#" className="text-indigo-600 hover:text-indigo-900">*/}
                            {/*        Edit*/}
                            {/*    </a>*/}
                            {/*</td>*/}
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default Table;