import React from 'react';

function Button(props) {
    return <button className="w-full flex items-center justify-center px-3 py-3 border border-transparent text-base font-medium rounded-md text-white disabled:bg-gray-300 bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-3" {...props}/>
}

export function SlimButton(props) {
return <button className="w-full flex items-center justify-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-white disabled:bg-gray-300 bg-indigo-600 hover:bg-indigo-700 md:py-2 md:text-lg md:px-3" {...props}/>
}

export function InlineButton(props) {
    return <button className="flex items-center justify-center px-3 border border-transparent text-base font-medium rounded-md text-white disabled:bg-gray-300 bg-indigo-600 hover:bg-indigo-700 md:text-md md:px-3" {...props}/>
}

export default Button;