import setAuthToken from "../components/common/setAuthToken";

import {GET_RENTALS, SET_CURRENT_USER} from "./types";
import {fetchJson} from "../common";
import axios from "axios";

//Login - Get User Token
export const loginUserViaTwitch = (history) => (dispatch) => {
  fetchJson('/api/backend/login-redirect').then((response) => {
    window.location = response.url;
  });
};

//Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//Log user out
export const logoutUser = () => (dispatch) => {
  axios.get("/api/backend/logout").then((res) => {window.location = "/";})
};
