import React from 'react';
import {CheckCircleIcon, XCircleIcon, ExclamationCircleIcon} from "@heroicons/react/outline";

function ConnectionPanel(
    {
        label,
        description,
        identifier,
        connected,
        warning = null, // only matters when connected === true
        RefreshConnection,
        InitConnection,
        className,
        additionalContent,
        ...props
    }
) {
    return <div className={`rounded border-2 m-2 p-4 ${className}`} {...props}>
        <div className="flex justify-between items-center mb-1">
            <div>{label}</div>
            {connected
                ? warning
                    ? <div className="flex">
                        {warning && warning}
                        <ExclamationCircleIcon className="h-6 w-6 ml-1 mr-1 text-yellow-500"/>
                    </div>
                    : <CheckCircleIcon className="h-6 w-6 mr-1 text-green-500"/>
                : <XCircleIcon className="h-6 w-6 mr-1 text-red-500"/>
            }
        </div>
        <div className={connected ? "flex justify-between items-center" : null}>
            {connected && identifier && <>
                <div>{identifier}</div>
                {RefreshConnection && RefreshConnection}
            </>}
            {!connected && InitConnection && InitConnection}
        </div>
        {additionalContent}
    </div>
}

export default ConnectionPanel;