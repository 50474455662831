import React from "react";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";

import {loginUserViaTwitch} from "../../actions/authActions"

function Landing({loginUserViaTwitch}) {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-64 w-auto"
                        src="/twify-icon-svg.svg"
                        alt="Workflow"
                    />
                    <h2 className="mt-6 bungee-font text-center text-3xl font-extrabold text-gray-900 flex justify-center items-center">
                        <span className="mt-2">Welcome to</span>
                        <img className="ml-2 h-10 w-auto" src="/twify.svg" alt="Twify"/>
                    </h2>
                </div>
                <div>
                    <button
                        onClick={loginUserViaTwitch}
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-twitchPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Sign in with your Twitch Account
                    </button>
                </div>

            </div>
        </div>
    )
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {loginUserViaTwitch})(Landing);
