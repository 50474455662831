import React from 'react';

function Input({className, ...props}) {
    return <input
        type="text"
        className={`border-2 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${className}`}
        {...props}
    />
}

export default Input;